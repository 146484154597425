body{
  font-family: 'Open Sans', sans-serif;
  background: #b8c7c1;
}
.block-1080{
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
}

.header{
  padding: 100px 0;
}
.header-wrp{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo a{
  display: inline-block;
  vertical-align: baseline;
  text-decoration: none;
  max-width: 135px;
  width: 100%;
}
.header-dsc {
	text-align: center;
	/* padding-top: 60px; */
	display: table;
	margin: 0 auto;
}
.header-dsc h2{
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  color: #fff;
  margin-bottom: 25px;
  font-family: 'Open Sans', sans-serif;
}
.header-dsc strong {
	display: inline-block;
	vertical-align: baseline;
	font-size: 25px;
	line-height: 1;
	color: #fff;
	font-weight: 600;
}
.header-back a {
	display: flex;
	justify-content: center;
	text-decoration: none;
	width: 80px;
	height: 80px;
	background: #fff;
	border-radius: 50px;
	align-items: center;
	font-size: 60px;
	color: #17c67a;
  margin-right: 20px;
}
.iftar-time-innr{
  text-align: center;
  padding-top: 60px;
}
.iftar-time-btm{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.iftar-time-wrp{
  padding-bottom: 50px;
}
.tab-list{
	background: rgb(24,176,154);
  background: linear-gradient(0deg, rgba(24,176,154,1) 15%, rgba(14,189,123,1) 100%);
	width: 100%;
	height: 60px;
  border-radius: 50px;
  padding:10px 0;
  position: relative;
  overflow: hidden;
}
.tab-list ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.tab-list ul li{
  float: left;
  width: 33.3333%;
  padding: 10px 10px;
  color: #fff;
  text-align: center;
}
.tab-list ul li span{
  /* margin-left: -140px; */
  display: inline-block;
  vertical-align: baseline;
  font-size: 13px;
}
/* .tab-list ul li:last-child span {
  margin-left: -50px;
} */
.tab-list ul li p{
  margin-bottom: 0;
}
.list-table-wrp table tbody tr td{
  width: 33.3333%;
}

.list-table-wrp table tbody tr td,
.list-table-wrp table thead tr th{
  background: transparent;
  border: 0;
  text-align: center;
}
.table-bordered > :not(caption) > * {
	border-width: 0;
}
.table-striped > tbody > tr:nth-of-type(2n+1) > * {
	--bs-table-accent-bg: 0;
	color: inherit;
}
.list-table-wrp {
  padding-top: 30px;
  overflow: hidden;
  position: relative;
  /* height: 400px; */
}
.list-table-scroll{
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: normal;
  height: 400px;
  padding-bottom: 30px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.list-table-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list-table-scroll{
    -ms-overflow-style: none; 
    scrollbar-width: none;  
  }
.footer{
  position: relative;
}
.footer-innr {
	max-width: 1080px;
	width: 100%;
	margin: 0 auto;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #17c67a;
	padding: 10px 10px;
}
.footer-innr p {
	margin-bottom: 0;
	font-size: 14px;
  color: #fff;
}
.footer-innr a {
  display: inline-block;
  vertical-align: baseline;
  text-decoration: underline;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}
.footer-innr a img{
  max-width: 120px;
  width: 100%;
}

.footer-innr i{
  color: #fff;
  font-size: 30px;
}
.list-table-wrp table tbody tr.selected{
  background:#aab8b3;
}
.tab-list-wrp{
  padding: 0 15px;
}
.error-page-wrp{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.error-page-wrp{
  text-align: center;
}
.error-page-wrp h1{
  font-size: 60px;
  line-height: 1;
  text-align: center;
  color:#f76652;
  text-transform: uppercase;
}
.error-page-wrp i {
  font-size: 100px;
  line-height: 1;
  display: block;
  padding-top: 15px;
  color: #12b887;
}




@media(max-width:1199px){

}

@media(max-width:991px){
  .list-table-wrp,
  .tab-list-wrp{
    padding: 0 15px;
  }
}

@media(max-width:767px){
  .container, .container-sm {
      max-width: 100%;
  }
  .header-dsc h2 {
    font-size: 25px;
    margin-bottom: 20px;
 }
 .header-dsc strong {
    font-size: 20px;
  }
  .header {
    padding: 70px 0;
}
}

@media(max-width:575px){
  .header-dsc h2 {
      font-size: 22px;
      margin-bottom: 15px;
  }
  .header-dsc strong {
      font-size: 18px;
  }

}

@media(max-width:479px){
  .iftar-time-btm span{
    font-size: 14px;
  }
  .iftar-time-innr span{
    font-size: 15px;
  }
  .iftar-time-innr h4{
    font-size: 18px;
  }
  .logo a img{
      max-width: 75px;
      width: 100%;
  }
  .header-dsc h2 {
      font-size: 15px;
      margin-bottom: 10px;
  }
  .header-dsc strong {
    font-size: 14px;
}
.header {
  padding: 30px 0;
}
.tab-list ul li{
  padding: 5px 0;
}
.tab-list ul li span {
  font-size: 13px;
}
.list-table-wrp table tr td{
  font-size: 14px;
}
.iftar-time-innr {
  text-align: center;
  padding-top: 20px;
}
.iftar-time-wrp {
  padding-bottom: 20px;
}
.tab-list {
  padding: 8px 5px;
  height: 50px;
}
.footer-innr p {
  font-size: 12px;
}
.iftar-time-btm {
  padding-top: 15px;
}

}


